var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('div',{staticClass:"modal fade",attrs:{"id":"modal-manual-assignment"}},[_c('div',{staticClass:"modal-dialog modal-md"},[_c('div',{staticClass:"modal-content"},[_c('div',{staticClass:"modal-header bg-frontera-top-left pt-2 pb-2"},[_c('h4',{staticClass:"modal-title text-white"},[_vm._v("Asignación Manual")]),_c('button',{ref:"closeModal",staticClass:"close text-white",attrs:{"type":"button","data-dismiss":"modal","aria-label":"Close","id":"close-modal"}},[_c('span',{attrs:{"aria-hidden":"true"}},[_vm._v("×")])])]),_c('div',{staticClass:"modal-body"},[_c('div',{staticClass:"form-group col-md-11"},[_c('label',{attrs:{"for":"origen"}},[_vm._v("Empresa")]),_c('v-select',{staticClass:"border rounded shadow-sm p-0",class:_vm.$v.form.empresa_id.$invalid
                  ? 'border-danger'
                  : 'border-success',attrs:{"placeholder":"empresa","label":"razon_social","options":_vm.listasForms.empresas,"reduce":(empresa) => empresa.id},on:{"search":_vm.buscarEmpresas},model:{value:(_vm.form.empresa_id),callback:function ($$v) {_vm.$set(_vm.form, "empresa_id", $$v)},expression:"form.empresa_id"}})],1),_c('div',{staticClass:"form-group col-md-11"},[_c('label',{attrs:{"for":"origen"}},[_vm._v("Conductor")]),_c('v-select',{staticClass:"border rounded shadow-sm p-0",class:_vm.$v.form.asignacion_id.$invalid
                  ? 'border-danger'
                  : 'border-success',attrs:{"placeholder":"Conductor","label":"nombre","options":_vm.listasForms.conductores,"reduce":(conductor) => conductor.id},on:{"search":_vm.buscarConductores},model:{value:(_vm.form.asignacion_id),callback:function ($$v) {_vm.$set(_vm.form, "asignacion_id", $$v)},expression:"form.asignacion_id"}})],1),_c('div',{staticClass:"form-group col-md-11"},[_c('label',[_vm._v("Sitio")]),_c('v-select',{staticClass:"border rounded shadow-sm p-0",class:_vm.$v.form.sitio_id.$invalid ? 'border-danger' : 'border-success',attrs:{"placeholder":"Sitio","label":"nombre","options":_vm.listasForms.sitios,"reduce":(sitio) => sitio.id},on:{"search":_vm.buscarSitios,"input":_vm.buscarBonos},model:{value:(_vm.form.sitio_id),callback:function ($$v) {_vm.$set(_vm.form, "sitio_id", $$v)},expression:"form.sitio_id"}})],1),(_vm.listasForms.bonos.length)?_c('div',{staticClass:"form-group col-md-11"},[_c('label',{attrs:{"for":"origen"}},[_vm._v("Bonos")]),_c('v-select',{staticClass:"border rounded shadow-sm p-0",class:_vm.form.bonos && _vm.form.bonos.length
                  ? 'border-success'
                  : 'border-danger',attrs:{"placeholder":"bonos","label":"codMonto","options":_vm.listasForms.bonos,"multiple":"","reduce":(bono) => bono.id},on:{"input":_vm.sumaBonos},model:{value:(_vm.form.bonos),callback:function ($$v) {_vm.$set(_vm.form, "bonos", $$v)},expression:"form.bonos"}})],1):_vm._e(),(!_vm.listasForms.bonos.length && _vm.form.sitio_id)?_c('div',{staticClass:"text-danger"},[_vm._v(" No se encontraron bonos disponibles para este sitio ")]):_vm._e(),_c('div',{staticClass:"row"},[(_vm.form.sitio_id)?_c('div',{staticClass:"col-md-12 mb-0 text-center mb-1 text-primary"},[_c('div',{staticClass:"row justify-content-center"},[_c('div',{staticClass:"col-md-6"},[_c('div',{staticClass:"info-box"},[_vm._m(0),_c('div',{staticClass:"info-box-content"},[_c('span',{staticClass:"info-box-text"},[_vm._v("Monto Total")]),_c('span',{staticClass:"info-box-number"},[_vm._v(" "+_vm._s("$ " + parseFloat(_vm.montoTotal).toLocaleString("es-ES"))+" ")])])])])])]):_vm._e()])]),_c('div',{staticClass:"modal-footer justify-content-between"},[_c('button',{directives:[{name:"show",rawName:"v-show",value:(!_vm.$v.form.$invalid && _vm.form.bonos && _vm.form.bonos.length),expression:"!$v.form.$invalid && form.bonos && form.bonos.length"}],staticClass:"btn btn-primary",attrs:{"type":"button"},on:{"click":function($event){return _vm.asignacionManual()}}},[_vm._v(" Asignar Bono ")])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('span',{staticClass:"info-box-icon bg-info elevation-1"},[_c('i',{staticClass:"fas fa-coins"})])
}]

export { render, staticRenderFns }