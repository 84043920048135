<template>
  <div>
    <highcharts :options="chartOptions"></highcharts>
  </div>
</template>

<script>
import { Chart } from "highcharts-vue";

export default {
  name: "GraficaEstadoBonos",

  components: {
    highcharts: Chart,
  },

  data() {
    return {
      chartOptions: {
        xAxis: {
          categories: [],
        },

        yAxis: {
          allowDecimals: false,
          min: 0,
          title: {
            text: "Cantidad De Bonos",
          },
        },

        exporting: {
          enabled: true,
        },
        title: {
          text: "",
          align: "center",
        },
        subtitle: {
          text: "CANTIDAD DE BONOS POR ESTADO",
          align: "center",
        },

        series: [
          {
            type: "column",
            name: ["Disponibles"],
            data: [],
            color: "#00C851",
          },
          {
            type: "column",
            name: "Asignados",
            data: [],
            color: "#0099CC",
          },
          {
            type: "column",
            name: "Pendientes",
            data: [],
            color: "#ffbb33",
          },
        ],

        credits: {
          enabled: false,
        },
        hoverBorderWidth: 10,
      },

      animation: {
        animateRotate: false,
        animateScale: true,
      },

      cantidadDisp: "",
      cantidadAsi: "",
      cantidadPen: "",
      categorias: [],
    };
  },

  methods: {
    getIndex(data) {
      this.chartOptions.xAxis.categories = [];
      this.chartOptions.series[0].data = [];
      this.chartOptions.series[1].data = [];
      this.chartOptions.series[2].data = [];

      Object.entries(data.groupBloques).forEach(([key, blq]) => {
        if (
          !this.$parent.filtros.sitio_id ||
          (this.$parent.filtros.sitio_id &&
            this.$parent.filtros.sitio_id == key)
        ) {
          if (blq.Sitio) {
            this.chartOptions.xAxis.categories.push(blq.Sitio);
          }

          if (blq.cantidadDisp) {
            this.chartOptions.series[0].data.push(blq.cantidadDisp);
          }

          if (blq.cantidadAsi) {
            this.chartOptions.series[1].data.push(blq.cantidadAsi);
          }

          if (blq.cantidadPen) {
            this.chartOptions.series[2].data.push(blq.cantidadPen);
          }
        }
      });
    },
  },
};
</script>
