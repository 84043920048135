<template>
  <div>
    <!-- Modal -->
    <div class="modal fade" id="modal-form-rank">
      <div class="modal-dialog modal-md">
        <div class="modal-content">
          <div class="modal-header bg-frontera-top-left pt-2 pb-2">
            <h4 class="modal-title text-white">Bonos por Rango</h4>
            <button
              type="button"
              class="close text-white"
              data-dismiss="modal"
              aria-label="Close"
              id="close-modal"
              ref="closeModal"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="form-group col-md-12">
              <label for="origen">Sitio</label>
              <v-select
                :class="[
                  $store.getters.getDarkMode ? 'dark-vselect' : '',
                  $v.form.sitio_id.$invalid ? 'is-invalid' : 'is-valid',
                ]"
                class="form-control form-control-sm p-0"
                v-model="sitio"
                placeholder="Sitio"
                label="nombre"
                :options="listasForms.sitios"
                :filterable="false"
                @search="buscarSitio"
                @input="selectSitio()"
              ></v-select>
            </div>
            <div class="row">
              <div class="col-lg-6">
                <div class="form-group">
                  <label for="codigo_inf">Cod. de Bono Inferior</label>
                  <input
                    type="number"
                    class="form-control form-control-sm"
                    id="codigo_inf"
                    placeholder="Numero Inferior"
                    v-model="form.codigo_inf"
                    :class="
                      $v.form.codigo_inf.$invalid ? 'is-invalid' : 'is-valid'
                    "
                  />
                </div>
                <div class="error" v-if="!$v.form.codigo_inf.required">
                  Ingrese un código Inferior
                </div>
              </div>
              <div class="col-lg-6">
                <div class="form-group">
                  <label for="codigo_sup">Cod. de Bono Superior</label>
                  <input
                    type="number"
                    class="form-control form-control-sm"
                    id="codigo_sup"
                    placeholder="Numero Superior"
                    v-model="form.codigo_sup"
                    :class="
                      $v.form.codigo_sup.$invalid ? 'is-invalid' : 'is-valid'
                    "
                    @blur="buscarDif()"
                  />
                </div>
                <div class="error" v-if="!$v.form.codigo_sup.required">
                  Ingrese un código Superior
                </div>
              </div>
              <div class="col-lg-12 mt-2">
                <div class="form-group">
                  <label for="codigo_sup">Tipo Bono</label>
                  <select
                    class="form-control form-control-sm"
                    v-model="form.tipo_bono"
                    :class="
                      $v.form.tipo_bono.$invalid ? 'is-invalid' : 'is-valid'
                    "
                  >
                    <option value>Seleccione...</option>
                    <option
                      v-for="tipoBono in $parent.listasForms.tipo_bonos"
                      :key="tipoBono.numeracion"
                      :value="tipoBono.numeracion"
                    >
                      {{ tipoBono.descripcion }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="col-lg-12 mt-2">
                <div class="form-group">
                  <label for="codigo_sup">Monto Bono</label>
                  <select
                    class="form-control form-control-sm"
                    v-model="form.monto_bono"
                    :class="
                      $v.form.monto_bono.$invalid ? 'is-invalid' : 'is-valid'
                    "
                  >
                    <option value>Seleccione...</option>
                    <option
                      v-for="montoBono in $parent.listasForms.monto_bonos"
                      :key="montoBono.numeracion"
                      :value="montoBono.numeracion"
                    >
                      {{ montoBono.descripcion }}
                    </option>
                  </select>
                </div>
              </div>
              <div class="form-group col-md-12">
                <label for="">Fecha Emision</label>
                <input
                  type="date"
                  class="form-control form-control-sm"
                  v-model="form.fecha_emision"
                  :class="
                    $v.form.fecha_emision.$invalid ? 'is-invalid' : 'is-valid'
                  "
                  @change="validarFecha()"
                />
              </div>
              <div class="form-group col-md-12">
                <label for="">Fecha vencimiento</label>
                <input
                  type="date"
                  class="form-control form-control-sm"
                  v-model="form.fecha_vencimiento"
                  :class="
                    $v.form.fecha_vencimiento.$invalid
                      ? 'is-invalid'
                      : 'is-valid'
                  "
                  @change="validarFecha()"
                />
              </div>
            </div>
          </div>
          <div class="modal-footer justify-content-between">
            <button
              type="button"
              class="btn btn-primary"
              v-show="!$v.form.$invalid"
              @click="saveBonos()"
            >
              Crear Bonos
            </button>
          </div>
        </div>
        <!-- /.modal-content -->
      </div>
      <!-- /.modal-dialog -->
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { required, minLength, maxLength } from "vuelidate/lib/validators";
import vSelect from "vue-select";

export default {
  name: "BonoRangos",
  components: {
    vSelect,
  },
  data() {
    return {
      form: {
        sitio_id: null,
        tipo_bono: null,
        monto_bono: null,
        fecha_emision: null,
        fecha_vencimiento: null,
      },
      sitio: [],
      listasForms: {
        sitios: [],
      },
    };
  },
  validations: {
    form: {
      codigo_inf: {
        required,
        maxLength: maxLength(12),
        minLength: minLength(10),
      },
      codigo_sup: {
        required,
        maxLength: maxLength(12),
        minLength: minLength(10),
      },
      sitio_id: {
        required,
      },
      tipo_bono: {
        required,
      },
      monto_bono: {
        required,
      },
      fecha_vencimiento: {
        required,
      },
      fecha_emision: {
        required,
      },
    },
  },
  methods: {
    buscarSitio(search, loading) {
      if (search != "") {
        let me = this;
        loading(true);
        var url = "api/admin/sitios/lista?sitio=" + search;
        axios
          .get(url)
          .then(function(response) {
            let respuesta = response.data;
            me.listasForms.sitios = respuesta;
            me.form.sitio_id = me.listasForms.sitios.id;
            loading(false);
          })
          .catch(function(error) {
            this.$swal({
              icon: "error",
              title: "Ocurrio un error" + error,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      }
    },

    buscarDif() {
      if (this.form.codigo_inf > this.form.codigo_sup) {
        this.$swal({
          title: "Fuera de Rango",
          text: "El código superior no puede ser menor al código inferior!",
          icon: "warning",
          confirmButtonText: "Aceptar",
        });
        this.form.codigo_sup = null;
      }
    },

    selectSitio() {
      this.form.sitio_id = "";
      if (this.sitio) {
        this.form.sitio_id = this.sitio.id;
      }
    },

    saveBonos() {
      this.$parent.cargando = true;
      if (!this.$v.form.$invalid) {
        axios({
          method: "POST",
          url: "/api/hidrocarburos/bonos",
          data: this.form,
        })
          .then((response) => {
            this.$refs.closeModal.click();
            this.limpiarForm();
            this.$swal({
              icon: "success",
              title: "El rango de bonos se creo exitosamente...",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
            this.$parent.cargando = false;
          })
          .catch((e) => {
            this.$parent.cargando = false;
            if (e.response.status == 500) {
              this.error = e.response.data.message;
              if (this.error.includes("1062 Duplicate entry")) {
                this.$swal({
                  icon: "error",
                  title:
                    "El código de bono ya se encuentra creado en la base de datos",
                  toast: true,
                  position: "top-end",
                  showConfirmButton: false,
                  timer: 3000,
                  timerProgressBar: true,
                });
              } else {
                this.$swal({
                  icon: "error",
                  title: "Ha ocurrido un error, intentelo nuevamente",
                  toast: true,
                  position: "top-end",
                  showConfirmButton: false,
                  timer: 3000,
                  timerProgressBar: true,
                });
              }
            } else {
              this.$swal({
                icon: "error",
                title: "Ocurrio un error" + e,
                toast: true,
                position: "top-end",
                showConfirmButton: false,
                timer: 3000,
                timerProgressBar: true,
              });
            }
            this.$parent.cargando = false;
          });
      }
    },

    limpiarForm() {
      this.form = {
        codigo_inf: null,
        codigo_sup: null,
        sitio_id: null,
        tipo_bono: null,
        monto_bono: null,
        fecha_emision: null,
        fecha_vencimiento: null,
      };
      this.sitio = [];
    },

    validarFecha() {
      // Se valida que la fecha inicial de la inspección sea menor que la fecha nueva
      if (this.form.fecha_vencimiento < this.form.fecha_emision) {
        this.form.fecha_vencimiento = null;
        this.$swal({
          icon: "error",
          title: `La fecha de vencimiento no puede ser menor a la fecha de emisión...`,
          toast: true,
          position: "top-end",
          showConfirmButton: false,
          timer: 3000,
          timerProgressBar: true,
        });
      }
    },
  },
};
</script>
