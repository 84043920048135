<template>
  <div>
    <!-- Modal -->
    <div class="modal fade" id="modal-manual-assignment">
      <div class="modal-dialog modal-md">
        <div class="modal-content">
          <div class="modal-header bg-frontera-top-left pt-2 pb-2">
            <h4 class="modal-title text-white">Asignación Manual</h4>
            <button
              type="button"
              class="close text-white"
              data-dismiss="modal"
              aria-label="Close"
              id="close-modal"
              ref="closeModal"
            >
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="form-group col-md-11">
              <label for="origen">Empresa</label>
              <v-select
                v-model="form.empresa_id"
                placeholder="empresa"
                label="razon_social"
                class="border rounded shadow-sm p-0"
                :options="listasForms.empresas"
                :class="
                  $v.form.empresa_id.$invalid
                    ? 'border-danger'
                    : 'border-success'
                "
                @search="buscarEmpresas"
                :reduce="(empresa) => empresa.id"
              ></v-select>
            </div>
            <div class="form-group col-md-11">
              <label for="origen">Conductor</label>
              <v-select
                v-model="form.asignacion_id"
                placeholder="Conductor"
                label="nombre"
                class="border rounded shadow-sm p-0"
                :options="listasForms.conductores"
                :class="
                  $v.form.asignacion_id.$invalid
                    ? 'border-danger'
                    : 'border-success'
                "
                @search="buscarConductores"
                :reduce="(conductor) => conductor.id"
              ></v-select>
            </div>
            <div class="form-group col-md-11">
              <label>Sitio</label>
              <v-select
                v-model="form.sitio_id"
                placeholder="Sitio"
                label="nombre"
                class="border rounded shadow-sm p-0"
                :class="
                  $v.form.sitio_id.$invalid ? 'border-danger' : 'border-success'
                "
                :options="listasForms.sitios"
                @search="buscarSitios"
                @input="buscarBonos"
                :reduce="(sitio) => sitio.id"
              ></v-select>
            </div>
            <div v-if="listasForms.bonos.length" class="form-group col-md-11">
              <label for="origen">Bonos</label>
              <v-select
                v-model="form.bonos"
                placeholder="bonos"
                label="codMonto"
                class="border rounded shadow-sm p-0"
                :options="listasForms.bonos"
                multiple
                :class="
                  form.bonos && form.bonos.length
                    ? 'border-success'
                    : 'border-danger'
                "
                :reduce="(bono) => bono.id"
                @input="sumaBonos"
              ></v-select>
            </div>
            <div
              v-if="!listasForms.bonos.length && form.sitio_id"
              class="text-danger"
            >
              No se encontraron bonos disponibles para este sitio
            </div>
            <div class="row">
              <div
                v-if="form.sitio_id"
                class="col-md-12 mb-0 text-center mb-1 text-primary"
              >
                <div class="row justify-content-center">
                  <div class="col-md-6">
                    <div class="info-box">
                      <span class="info-box-icon bg-info elevation-1"
                        ><i class="fas fa-coins"></i
                      ></span>
                      <div class="info-box-content">
                        <span class="info-box-text">Monto Total</span>
                        <span class="info-box-number">
                          {{
                            "$ " +
                              parseFloat(montoTotal).toLocaleString("es-ES")
                          }}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer justify-content-between">
            <button
              type="button"
              class="btn btn-primary"
              v-show="!$v.form.$invalid && form.bonos && form.bonos.length"
              @click="asignacionManual()"
            >
              Asignar Bono
            </button>
          </div>
        </div>
        <!-- /.modal-content -->
      </div>
      <!-- /.modal-dialog -->
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { required } from "vuelidate/lib/validators";
import vSelect from "vue-select";

export default {
  name: "AsignacionManual",
  components: {
    vSelect,
  },
  data() {
    return {
      montoTotal: 0,
      form: {
        asignacion_type: "App\\Conductor",
        asignacion_id: null,
        sitio_id: null,
        empresa_id: null,
        bonos: [],
      },
      listasForms: {
        conductores: [],
        empresas: [],
        sitios: [],
        bonos: [],
      },
    };
  },
  validations: {
    form: {
      empresa_id: {
        required,
      },
      asignacion_id: {
        required,
      },
      sitio_id: {
        required,
      },
    },
  },
  methods: {
    asignacionManual() {
      let self = this;
      self.$parent.cargando = true;
      if (!self.$v.form.$invalid) {
        let data = {
          form: self.form,
          montoTotal: self.montoTotal,
        };
        axios({
          method: "POST",
          url: "/api/hidrocarburos/asignacionManual",
          data: data,
        })
          .then((response) => {
            let data = response.data;
            self.$parent.cargando = false;
            self.limpiarForm();
            self.$refs.closeModal.click();
            window.open(data.url, "_blank");
          })
          .catch((e) => {
            self.$parent.cargando = false;
            self.$swal({
              icon: "error",
              title: "Ocurrio un error",
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      }
    },

    limpiarForm() {
      this.form = {
        asignacion_type: "App\\Conductor",
        asignacion_id: null,
      };
      this.form.bonos = [];
      this.listasForms.bonos = [];
      this.montoTotal = 0;
    },

    buscarConductores(search, loading) {
      if (search != "") {
        let self = this;
        loading(true);
        var url = "api/admin/conductores/lista?nombre=" + search;
        axios
          .get(url)
          .then(function(response) {
            self.listasForms.conductores = response.data;
            loading(false);
          })
          .catch(function(error) {
            this.$swal({
              icon: "error",
              title: "Ocurrió un error - " + error,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      }
    },

    buscarEmpresas(search, loading) {
      if (search != "") {
        let self = this;
        loading(true);
        var url = "api/admin/empresas/lista?razon=" + search;
        axios
          .get(url)
          .then(function(response) {
            self.listasForms.empresas = response.data;
            loading(false);
          })
          .catch(function(error) {
            self.$swal({
              icon: "error",
              title: "Ocurrio un error" + error,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      }
    },

    buscarSitios(search, loading) {
      if (search != "") {
        let self = this;
        loading(true);
        var url = "api/admin/sitios/lista?sitio=" + search;
        axios
          .get(url)
          .then(function(response) {
            self.listasForms.sitios = response.data;
            loading(false);
          })
          .catch(function(error) {
            self.$swal({
              icon: "error",
              title: "Ocurrio un error" + error,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
            loading(false);
          });
      }
    },

    buscarBonos() {
      let self = this;
      self.form.bonos = [];
      self.listasForms.bonos = [];
      if (self.form.sitio_id) {
        var url = "api/hidrocarburos/bonos/lista";
        axios
          .get(url, {
            params: {
              sitio_id: self.form.sitio_id,
            },
          })
          .then(function(response) {
            self.listasForms.bonos = response.data;
          })
          .catch(function(error) {
            self.$swal({
              icon: "error",
              title: "Ocurrio un error" + error,
              toast: true,
              position: "top-end",
              showConfirmButton: false,
              timer: 3000,
              timerProgressBar: true,
            });
          });
      }
      self.montoTotal = self.listasForms.bonos.length ? self.montoTotal : 0;
    },

    sumaBonos() {
      this.montoTotal = 0;
      this.form.bonos.forEach((bono) => {
        let bonoEncontrado = this.listasForms.bonos.find(
          (element) => element.id === bono
        );
        if (bonoEncontrado) {
          this.montoTotal += parseInt(bonoEncontrado.nMonto);
        }
      });
    },
  },
};
</script>
